<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="image-wrapper_1 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd2512287adbfeb8f857bd3b447e634e50796d348415fac44d73e90805fb48659.png"
        />
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng4584ee5f51cd0024e9dac6ba0be6a65efc085aadb99ace0eab9fda86498461ad.png"
        />
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng944a0d2a2ea0b3eb6cc0a6f816543033e84e89500f29f107f4614d3b1aaa99ee.png"
        />
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng9fa123d0abe332a96e6e2df6e056a6e78e04d53e18657b4ef81c9c219b9d07d0.png"
        />
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6d8fba0d7dfeb281f644439fc03e4dd31c4d6023470e6b76cc911ffd74a0c967.png"
        />
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng29efa7a04e20dfa8f389069893fb605dca349730782bdaffdb0436bc70e74255.png"
        />
      </div>

      <div class="box_1 flex-row justify-between">
        <span class="text_1" @click="openDonationHall">校友捐赠文物展</span>
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/logo_90.png"
        />
      </div>

      <div class="box_2 flex-col" @click="openDonationHall">
        <div class="box_3 flex-row">
          <div class="image-text_1 flex-row justify-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngea1aa7f5368948cec1478bfe762478d2a41a4785cb4a83b9020b65df2dc12292.png"
            />
            <span class="text-group_1">·校友捐赠文物展</span>
          </div>
        </div>
        <div
          class="box_4 flex-row"
          v-on:mousemove="MouseOver1"
          v-on:mouseout="MouseOut1"
        >
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngb08f5bdac18c17794e9a51ffe0c768176d9767aa791d13b16c3baa7f90a0a082.png"
          />

          <span class="paragraph_1">
            我校1951届毕业生邓禹先生、1960届毕业生关汉亨先生，心系母校，热心公益，将自己多年来历尽艰辛收藏的文物，捐赠给母校图书馆，此等精神，实圣哲所提倡，为师生共钦仰，母校以之为荣，特辟文博馆以展示，使莘莘学子得到陶冶和教育。
            <br />
            2008年12月25日，华南师范大学七十五周年华诞之际，文博馆建成开馆。
            <br />
            文博馆藏品分三大系列:
            <br />
            一、陶器瓷器，这部分为邓禹先生捐藏为主，邓先生以陶瓷器和古生物化石收藏知名;
            <br />
            二、铜镜和钱币，以关汉亨先生捐藏为主，关先生精于泉学，有《半两货币图说》等论著;
            <br />
            三、中国古籍，此部分以华南师范大学所藏古籍为主。
            <img
              src="./assets/img/play.png"
              class="content1Play"
              v-show="content1Play"
            />
          </span>
        </div>
      </div>
      <img
        class="thumbnail_1"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngd72aefe46c807353369c5b586d532388505834bc43f543a2c0c2e72b6f4af24d.png"
      />
      <div class="box_5 flex-col" @click="openDonationHall" ></div>
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng9dbba2e41212412831aee2a874ecc9e8c3f66db06edf9c2c932835ed9a3d7931.png"
      />
    </div>
    <div class="section_2 flex-col">
      <div class="group_1 flex-col">
        <div class="image-wrapper_2 flex-row">
          <img
            class="image_10"
            referrerpolicy="no-referrer"
            src="./assets/img/logo_90.png"
          />
        </div>
        <div class="text-wrapper_1 flex-row">
          <span class="text_2" @click="openLibraryHall">图书馆欢迎您！</span>
        </div>
        <div class="group_2 flex-row" @click="openLibraryHall">
          <div
            class="group_3 flex-col"
            v-on:mousemove="MouseOver2"
            v-on:mouseout="MouseOut2"
          >
            <div class="section_3 flex-row">
              <div class="image-text_2 flex-row justify-between">
                <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngb133ab4c8ecd6314d561cab73b3fda15402069cee3474da9cc0854986b3c05c3.png"
                />
                <span class="text-group_2">·石牌校园图书馆空间展</span>
              </div>
            </div>
            <div class="section_4 flex-row">
              <img
                class="image_11"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb08f5bdac18c17794e9a51ffe0c768176d9767aa791d13b16c3baa7f90a0a082.png"
              />
              <span class="text_3">
                华南师范大学图书馆的前身可以追溯到1933年建立的勷勤大学师范学院图书馆。1939年，勷勤大学师范学院图书馆随校更名为广东文理学院图书馆。中华人民共和国成立前夕，图书馆馆舍3576平方米，藏书约10万册。1951年，在广东文理学院图书馆基础上，调集原中山大学师范学院、华南联合大学教育系的图书设备，创立了华南师范学院图书馆。1952年，岭南大学教育系、广西大学教育系、湖南大学地理系、南昌大学部分文科系和海南师专并入华南师范学院，图书资料随之并入。1980年，图书馆扩建至8356平方米，阅览席位1300个。1982年10月，华南师范学院更名为华南师范大学，图书馆随之更名为华南师范大学图书馆。
                <img
                  class="content2Play"
                  src="./assets/img/play.png"
                  v-show="content2Play"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="image-wrapper_3 flex-row">
          <img
            class="thumbnail_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd72aefe46c807353369c5b586d532388505834bc43f543a2c0c2e72b6f4af24d.png"
          />
        </div>
      </div>
    </div>

    <!-- <template>
      <van-swipe :autoplay="6000" @change="onChange" class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, index) in images" :key="index">
          <img :src="item.path" />
        </van-swipe-item>

      </van-swipe>

    </template> -->

    <div class="section_5 flex-col">
      <div class="box_6 flex-row justify-between">
        <img
          class="image_12"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd7af1112217ea235eaaa05ae35b1dd0dc07d9c2d3be531c7829a5ef93a1b8801.png"
        />
        <div class="box_7 flex-col">
          <div class="box_8 flex-col"></div>
          <span class="text_4">专题展览</span>
        </div>
      </div>

      <div class="box_9 flex-row">
        <div class="image-wrapper_4 flex-col" @click="preLeft">
          <img
            id="image_13"
            class="image_13"
            referrerpolicy="no-referrer"
            :src="currentImageLeft(currentIndex)"
          />
          <img class="click_left" src="./assets/img/left.png" />
        </div>

        <div
          id="imgPosition"
          class="imgPosition"
          v-on:mousemove="MouseOver"
          v-on:mouseout="MouseOut"
        >
          <van-swipe
            class="flex-col justify-end"
            :show-indicators="false"
            :touchable="false"
          >
            <van-swipe-item v-for="(item, index) in images" :key="index">
              <div @click="onClick(currentIndex)">
                <img
                  :src="currentImage"
                  class="group_4"
                  referrerpolicy="no-referrer"
                />
                <img
                  class="oval"
                  src="./assets/img/play.png"
                  v-show="content3Play"
                />

                <div class="block_1 flex-row">
                  <div class="text-group_3 flex-col justify-between">
                    <span class="text_5"
                      ><template>
                        {{ currentTitle(currentIndex) }}
                      </template>
                    </span>

                    <span class="text_6"
                      ><template>
                        {{ currentContent(currentIndex) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>

        <div class="image-wrapper_5 flex-col" @click="preRight">
          <img
            id="image_15"
            class="image_15"
            referrerpolicy="no-referrer"
            :src="currentImageRight(currentIndex)"
          />
          <img class="click_right" src="./assets/img/right.png" />
        </div>
      </div>

      <div class="image-wrapper_6 flex-row">
        <img
          class="thumbnail_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng422a0c7f36e70d6db729e8d0136c8f56cbb8d3dad7b76b5232d829915f422649.png"
        />
      </div>
    </div>

    <div class="section_6 flex-col">
      <div class="box_10 flex-col">
        <div class="group_5 flex-row justify-between">
          <img
            class="image_16"
            referrerpolicy="no-referrer"
            src="./assets/img/LIBTOP.png"
          />
          <div class="block_2 flex-col">
            <div class="group_6 flex-col"></div>
            <span class="text_7">联图云共享展厅</span>
          </div>
        </div>
        <div class="group_7 flex-row" @click="enterLIBTOP">
          <div class="text-wrapper_2 flex-col">
            <span class="text_8">进入展厅</span>
          </div>
        </div>
      </div>

      <div class="box_11 flex-col">
        <div class="section_7 flex-col">
          <div class="text-wrapper_3 flex-row">
            <span class="text_9">华南师范大学图书馆微信公共号</span>
          </div>
          <div class="box_12 flex-row justify-between">
            <span class="text_10">友情链接：</span>
            <span class="text_11">华南师范大学官网</span>
            <div class="group_8 flex-col"></div>
            <span class="text_12">华南师范大学图书馆官网</span>
          </div>
          <div class="image-wrapper_7 flex-row justify-between">
            <img
              class="image_17"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng852f00e4dbc4196bed1dc7cf7796ad489b8ffd268412989cdf500cb3b88a3181.png"
            />
            <img
              class="image_18"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng4536bee7216fd52e3d7f2aebb9fa76dd8da733b83a49ab7111d58babbae5e8bd.png"
            />
          </div>
        </div>
        <img
          class="image_19"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngef1fcf6c64498ba4303121d0c49c730ba261d78659ec1a219bfcce44e83492ee.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content1Play: false,
      content2Play: false,
      content3Play: false,
      currentIndex: 0,
      constants: {},
      images: [
        {
          path: require("./assets/img/redculture1.jpg"),
          content:
            "今年,是毛泽东诞辰130周年,同时是华南师范大学建校90周年。华南师范大学历史文化学院课题组协同中国文化信息协会共色收藏工作委员会和陕西省收藏家协会等单位，拮取关于毛泽东口述史料的开山之作——《毛泽东自传》和《红星照耀中国》的中英文版本100余种，在这里举办专题展览，既是为校庆献礼，更是旨在通过百余件珍贵史料文献的展出，缅怀毛泽东的传奇人生和光辉伟业。",
          index: 1,
          title: "《毛泽东自传》《红星照耀中国》珍稀版本展",
          address:
            "https://lib-hall.scnu.edu.cn/web/#/threeShow?path=https%3A%2F%2Fweb.hall.bcnew.tech%2Fbase%2Fhall%2FsendHeader%2F1712734485073166337%3Furl%3Dhttps%3A%2F%2Frealsee.cn%2FXQeeaj8p&amp;id=1712734485073166337",
          smallPicture: require("./assets/img/redculture11.jpg"),
        },
        {
          path: require("./assets/img/history1.jpg"),
          content:
            "恰逢学校建校90周年,伴随学校走过90年发展历程的图书馆见证了无数个艰辛与光辉的时刻。图书馆是学校文化底蕴和办学水平的标志之一，它的90年发展正是学校发展壮大的一个缩影。图书馆的历史，亦即馆藏建设的历史、馆舍环境和技术设施建设的历史。图书馆服务发展的历史，尤属图书馆人及其奉献的历史。",
          index: 2,
          title: "华南师大图书馆馆史展",
          address:
            "https://lib-hall.scnu.edu.cn/web/#/threeShow?path=https%3A%2F%2Fweb.hall.bcnew.tech%2Fbase%2Fhall%2FsendHeader%2F1722172075710939138%3Furl%3Dhttps%3A%2F%2Frealsee.cn%2FBr33jpE6&id=1722172075710939138",
          smallPicture: require("./assets/img/history11.jpg"),
        },
        {
          path: require("./assets/img/Arab1.jpg"),
          content:
            " 2023年10月18日,第三届“一带一路”国际合作高峰论坛开幕式在北京盛大举办，近30位国家元首、出席活动，引起全世界瞩目。为了弘扬“一带一路”精神，让广大师生了解异国文化，加强国际文化交流，拓宽国际视野，理解不同文化背景下群体之间的差异，进一步促进中外文明的互学互鉴，华南师范大学图书馆与国际交流合作处联合组织举办中外人文交流互鉴活动。本年度的中外人文交流互鉴来到沙特站，带领师生走进沙特阿拉伯，揭开中东世界的神秘面纱。",
          index: 3,
          title: "沙砾遗珍-沙特阿拉伯文化展",
          address:
            "https://lib-hall.scnu.edu.cn/web/#/threeShow?path=https%3A%2F%2Fweb.hall.bcnew.tech%2Fbase%2Fhall%2FsendHeader%2F1718911812425547778%3Furl%3Dhttps%3A%2F%2Frealsee.cn%2FVpvvqPKj&amp;id=1718911812425547778",
          smallPicture: require("./assets/img/Arab11.jpg"),
        },
      ],
    };
  },
  computed: {
    currentImageLeft() {
      return function (index) {
        let CurrentIndex =
          (index - 1 + this.images.length) % this.images.length;
        return this.images[CurrentIndex].smallPicture;
      };
    },
    currentImage() {
      return this.images[this.currentIndex].path;
    },
    currentImageRight() {
      return function (index) {
        let CurrentIndex = (index + 1) % this.images.length;
        return this.images[CurrentIndex].smallPicture;
      };
    },
    currentTitle() {
      return function (index) {
        return this.images[index].title;
      };
    },
    currentContent() {
      return function (index) {
        return this.images[index].content;
      };
    },
  },
  created() {
    // console.log(this.images.length,'this.images.length');
    // vue
    // this.changeStyle();
  },
  methods: {
    openDonationHall() {
      window.open(
        "https://lib-hall.scnu.edu.cn/web/#/threeShow?path=https%3A%2F%2Fweb.hall.bcnew.tech%2Fbase%2Fhall%2FsendHeader%2F1695305183941668866%3Furl%3Dhttps%3A%2F%2Frealsee.cn%2F8YEE45dx&amp;id=1695305183941668866",
        "_blank"
      );
    },
    openLibraryHall() {
      window.open(
        "https://lib-hall.scnu.edu.cn/web/#/threeShow?path=https%3A%2F%2Fweb.hall.bcnew.tech%2Fbase%2Fhall%2FsendHeader%2F1695473158837547010%3Furl%3Dhttps%3A%2F%2Frealsee.cn%2FlJwwYZO0&amp;id=1695473158837547010",
        "_blank"
      );
    },

    onClick(index) {
      console.log(index);
      let openUrl = this.images[index].address;
      window.open(openUrl, "_blank");
    },
    preLeft() {
      let getdom1 = document.getElementById("imgPosition");
      let getdom2 = document.getElementById("image_13");
      let getdom3 = document.getElementById("image_15");
      getdom1.style.transform = `translateX(2%) scale(0.9,0.9)`;
      getdom1.style.transition = ".5s";
      getdom2.style.transform = `translateX(10%) scale(1.2,1.2)`;
      getdom2.style.transition = ".5s";
      getdom3.style.transform = `translateX(10%) scale(0.9,0.9)`;
      getdom3.style.transition = ".5s";

      setTimeout(() => {
        this.currentIndex =
          (this.currentIndex - 1 + this.images.length) % this.images.length;
        getdom1.style.transform = `translateX(0%)`;
        getdom1.style.transition = "none";
        getdom2.style.transform = `translateX(0%)`;
        getdom2.style.transition = "none";
        getdom3.style.transform = `translateX(0%)`;
        getdom3.style.transition = "none";
      }, 500);
    },
    preRight() {
      let getdom1 = document.getElementById("imgPosition");
      let getdom2 = document.getElementById("image_13");
      let getdom3 = document.getElementById("image_15");
      getdom1.style.transform = `translateX(-2%) scale(0.9,0.9)`;
      getdom1.style.transition = ".5s";
      getdom2.style.transform = `translateX(-10%) scale(0.9,0.9)`;
      getdom2.style.transition = ".5s";
      getdom3.style.transform = `translateX(-10%) scale(1.2,1.2)`;
      getdom3.style.transition = ".5s";

      setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
        getdom1.style.transform = `translateX(0%)`;
        getdom1.style.transition = "none";
        getdom2.style.transform = `translateX(0%)`;
        getdom2.style.transition = "none";
        getdom3.style.transform = `translateX(0%)`;
        getdom3.style.transition = "none";
      }, 500);
    },
    enterLIBTOP() {
      window.open("https://web.hall.bcnew.tech/#/list", "_blank");
      // window.location.href = "https://web.hall.bcnew.tech/#/list";
    },
    MouseOver1() {
      //移入
      this.content1Play = true;
    },
    MouseOut1() {
      this.content1Play = false;
    },
    MouseOut2() {
      this.content2Play = false;
    },
    MouseOver2() {
      //移入
      this.content2Play = true;
    },

    MouseOver() {
      this.content3Play = true;
      // const palyBtn = document.getElementsByClassName("oval");
      // console.log('移入');
      // // const palyBtn = document.getElementsByClassName("oval");
      // // console.log(palyBtn,'palyBtn');
      // // palyBtn.innerText='';
      // // document.getElementById("oval").innerHTML='';
      // let palyBtn = document.getElementsByClassName("oval");
      // document.body.removeChild(palyBtn);
    },
    MouseOut() {
      this.content3Play = false;
      // console.log("移出");
      // let palyBtn = document.getElementsByClassName("oval");
      // // console.log(palyBtn,'palyBtn');
      // // palyBtn.innerText='';
      // // //
      // // document.getElementById("oval").innerHTML='';
      // //
      // document.body.removeChild(palyBtn);
    },
  },
};
</script>
<!-- src="../../assets/js/index.js" -->

<!-- <script type="text/javascript">

$(".box_9 .imgPosition ").hover(
    function () {
      $(this).find(".oval").show();
      $(this)
        .find(".group_4")
        .stop(true)
        .animate(
          { width: "75.33vw", height: "8.55vw", left: "-7px", top: "-10px" },
          200
        );
    },
    function () {
      $(this).find(".oval").hide();
      $(this)
        .find(".group_4")
        .stop(true)
        .animate(
          { width: "70.33vw", height: "8.55vw", left: "0", top: "0" },
          100
        );
    }
  );
</script> -->
<style scoped lang="css" src="./assets/index.response.css"></style>
